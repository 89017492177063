<template>
  <v-card v-if="objekt" min-width="250px" variant="outlined">
    <v-toolbar title="Objekt">
      <v-btn icon="mdi-refresh" @click="objekt_erstellen"> </v-btn>
    </v-toolbar>

    <v-card subtitle="Kauf" class="my-1">
      <v-card-text>
        <div>
          <div class="d-flex justify-space-between">
            <span>Kaufpreis</span>
            <span>{{ objekt.kaufpreis }} €</span>
          </div>
          <v-slider
            @end="objekt_aendern(objekt)"
            v-if="edit_mode"
            v-model="objekt.kaufpreis"
            min="300000"
            max="2000000"
            step="25000"
          ></v-slider>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Renovierungskosten</span>
            <span>{{ objekt.renovierungskosten }} €</span>
          </div>
          <v-slider
            v-if="edit_mode"
            @end="objekt_aendern(objekt)"
            v-model="objekt.renovierungskosten"
            min="0"
            max="500000"
            step="25000"
            show-ticks
          ></v-slider>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Makler Provision</span>
            <span
              >{{
                Math.round((objekt.makler * objekt.kaufpreis) / 100)
              }}
              €</span
            >
          </div>
          <v-slider
            v-if="edit_mode"
            @end="objekt_aendern(objekt)"
            v-model="objekt.makler"
            min="0"
            max="7.14"
            step="0.01"
            thumb-label
          >
            <template v-slot:append>
              <v-text-field
                v-model="objekt.makler"
                type="number"
                style="width: 80px"
                density="compact"
                hide-details
                variant="outlined"
              ></v-text-field>
            </template>
          </v-slider>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Notar</span>
            <span>{{ (objekt.notar * objekt.kaufpreis) / 100 }} €</span>
          </div>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Grunderwerbssteuer</span>
            <span
              >{{
                (objekt.grunderwerbssteuer * objekt.kaufpreis) / 100
              }}
              €</span
            >
          </div>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Grundbucheintrag</span>
            <span>{{ (objekt.grundbuch * objekt.kaufpreis) / 100 }} €</span>
          </div>
        </div>
        <v-divider thickness="2" class="mt-3"></v-divider>
        <div>
          <div class="d-flex justify-space-between font-weight-bold">
            <span>Gesamt</span>
            <span>{{ objekt.gesamtpreis }} €</span>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card subtitle="Wohnen" class="my-2">
      <v-card-text>
        <div>
          <div class="d-flex justify-space-between">
            <span>Wohnfläche</span>
            <span>{{ objekt.wohnflaeche }} m²</span>
          </div>
          <v-slider
            @end="objekt_aendern(objekt)"
            v-if="edit_mode"
            v-model="objekt.wohnflaeche"
            min="70"
            max="600"
            step="10"
          ></v-slider>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Nebenkosten</span>
            <span>{{ objekt.nebenkosten }} €</span>
          </div>
          <v-slider
            @end="objekt_aendern(objekt)"
            v-if="edit_mode"
            v-model="objekt.nebenkosten"
            min="400"
            max="1800"
            step="50"
            show-ticks
          ></v-slider>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Rücklagen</span>
            <span>{{ objekt.ruecklagen }} €</span>
          </div>
          <v-slider
            @end="objekt_aendern(objekt)"
            v-if="edit_mode"
            v-model="objekt.ruecklagen"
            min="0"
            max="1000"
            step="50"
            show-ticks
          ></v-slider>
        </div>
      </v-card-text>
    </v-card>
    <v-card subtitle="Miete" class="mt-1">
      <v-card-text>
        <div>
          <div class="d-flex justify-space-between">
            <span>Mieteinnahmen</span>
            <span>{{ objekt.mieteinnahmen }} €</span>
          </div>
          <v-slider
            @end="objekt_aendern(objekt)"
            v-if="edit_mode"
            v-model="objekt.mieteinnahmen"
            min="0"
            max="2000"
            step="50"
            show-ticks
          ></v-slider>
        </div>
        <div>
          <div class="d-flex justify-space-between">
            <span>Fläche vermietet</span>
            <span>{{ objekt.wohnflaeche_vermietet }} m²</span>
          </div>
          <v-slider
            @end="objekt_aendern(objekt)"
            v-if="edit_mode"
            v-model="objekt.wohnflaeche_vermietet"
            min="0"
            :max="objekt.wohnflaeche"
            step="5"
            show-ticks
          ></v-slider>
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "KaufObjekt",
  methods: {
    ...mapMutations(["objekt_erstellen", "objekt_aendern"]),
  },
  computed: {
    ...mapGetters(["objekt", "edit_mode"]),
  },
  mounted() {
    if (this.objekt == null) {
      this.objekt_erstellen();
    }
  },
};
</script>

<script setup></script>
