<template>
  <v-card class="pa-0">
    <v-toolbar title="Kaufende Parteien">
      <v-btn icon="mdi-plus" @click="partei_erstellen"> </v-btn>
    </v-toolbar>
    <div class="d-flex justify-space-around flex-wrap pa-0">
      <v-card
        v-for="(partei, index) in parteien"
        :key="index"
        class="ma-0 pa-2"
        min-width="300px"
        :color="this.color[index]"
        variant="tonal"
      >
        <v-toolbar :title="edit_mode ? null : partei.name" density="compact">
          <v-text-field
            v-if="edit_mode"
            @change="partei_aendern(partei)"
            v-model="partei.name"
            variant="solo"
            hide-details=""
          ></v-text-field>
          <v-btn
            v-if="edit_mode"
            style="position: absolute; right: 0px"
            size="small"
            icon="mdi-delete"
            @click="partei_loeschen(index)"
          ></v-btn>
        </v-toolbar>
        <v-card class="mb-2"  subtitle="Kauf">
          <v-card-text>
            <div>
              <div
                class="d-flex justify-space-between"
                :class="kredit_anteil_summe == 100 ? null : 'text-red'"
              >
                <span>
                  <v-icon
                    v-if="edit_mode || kredit_anteil_summe != 100"
                    @click="fehlenden_kredit_anteil_berechnen(partei)"
                    :color="kredit_anteil_summe == 100 ? 'grey' : 'green'"
                    icon="mdi-equalizer"
                    size="medium"
                  ></v-icon>
                  Prozentualer Anteil</span
                >

                <span>{{ partei.kredit_anteil }} %</span>
              </div>
              <v-slider
                v-if="edit_mode"
                :color="kredit_anteil_summe == 100 ? null : 'red'"
                @end="partei_aendern(partei)"
                min="0"
                max="100"
                step="0.5"
                v-model="partei.kredit_anteil"
              ></v-slider>
            </div>
            <div class="d-flex justify-space-between">
              <span>Kaufpreis</span>
              <span
                >{{
                  Math.round((objekt.gesamtpreis * partei.kredit_anteil) / 100)
                }}
                €</span
              >
            </div>
            <div>
              <div class="d-flex justify-space-between">
                <span>Eigenanteil</span>
                <span>{{ partei.eigenanteil }} €</span>
              </div>
              <v-slider
                v-if="edit_mode"
                @end="partei_aendern(partei)"
                v-model="partei.eigenanteil"
                min="0"
                max="600000"
                step="10000"
              ></v-slider>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="my-1" subtitle="Kredit" v-if="partei.kredit.hoehe > 0">
          <v-card-text>
            <div class="d-flex justify-space-between">
              <span>Kreditsumme</span>
              <span>{{ partei.kredit.hoehe }} €</span>
            </div>
            <div>
              <div class="d-flex justify-space-between">
                <span>Zinssatz Bank</span>
                <span>{{ partei.zinssatz_bank }} %</span>
              </div>
              <v-slider
                v-if="edit_mode"
                @end="partei_aendern(partei)"
                v-model="partei.zinssatz_bank"
                min="2"
                max="4.5"
                step="0.05"
                show-ticks
              ></v-slider>
            </div>
            <div>
              <div class="d-flex justify-space-between">
                <span>Anfangstilgung</span>
                <span>{{ partei.anfangstilgung }} %</span>
              </div>
              <v-slider
                v-if="edit_mode"
                @end="partei_aendern(partei)"
                v-model="partei.anfangstilgung"
                min="1"
                max="5"
                step="0.1"
                show-ticks
              ></v-slider>
            </div>
            <div class="d-flex justify-space-between">
              <span>monatl. Rate</span>
              <span>{{ Math.round(partei.kredit.rate) }} €</span>
            </div>
          </v-card-text>
        </v-card>
        <v-card subtitle="Wohnen" class="my-1">
          <v-card-text>
            <div>
              <div
                class="d-flex justify-space-between"
                :class="
                  wohnflaeche_verteilt_summe == objekt.wohnflaeche
                    ? null
                    : 'text-red'
                "
              >
                <span>
                  <v-icon
                    v-if="edit_mode || kredit_anteil_summe != 100"
                    @click="fehlende_wohnflaeche_berechnen(partei)"
                    :color="
                      wohnflaeche_verteilt_summe == objekt.wohnflaeche
                        ? 'grey'
                        : 'green'
                    "
                    icon="mdi-equalizer"
                    size="medium"
                  ></v-icon>
                  Wohnfläche</span
                >

                <span>{{ partei.wohnflaeche }} m²</span>
              </div>
              <v-slider
                v-if="edit_mode"
                :color="
                  wohnflaeche_verteilt_summe == objekt.wohnflaeche
                    ? null
                    : 'red'
                "
                @end="partei_aendern(partei)"
                v-model="partei.wohnflaeche"
                min="20"
                :max="objekt.wohnflaeche"
                step="5"
              ></v-slider>
            </div>
            <div class="d-flex justify-space-between">
              <span>Rücklagen</span>
              <span>{{ partei.ruecklagen }} €</span>
            </div>
            <div class="d-flex justify-space-between">
              <span>Nebenkosten</span>
              <span>{{ partei.nebenkosten }} €</span>
            </div>
          </v-card-text>
        </v-card>
        <v-card subtitle="Miete" v-if="objekt.mieteinnahmen > 0">
          <v-card-text>
            <div>
              <div
                class="d-flex justify-space-between"
                :class="mieteinnahmen_anteil_summe == 100 ? null : 'text-red'"
              >
                <span>
                  <v-icon
                    v-if="edit_mode || kredit_anteil_summe != 100"
                    @click="fehlenden_miet_anteil_berechnen(partei)"
                    :color="
                      mieteinnahmen_anteil_summe == 100 ? 'grey' : 'green'
                    "
                    icon="mdi-equalizer"
                    size="medium"
                  ></v-icon>
                  Prozentualer Anteil</span
                >

                <span>{{ partei.mieteinnahmen_anteil }} %</span>
              </div>
              <v-slider
                v-if="edit_mode"
                :color="mieteinnahmen_anteil_summe == 100 ? null : 'red'"
                @end="partei_aendern(partei)"
                min="0"
                max="100"
                step="1"
                v-model="partei.mieteinnahmen_anteil"
              ></v-slider>
            </div>
            <div class="d-flex justify-space-between">
              <span>Mieteinnahmen</span>
              <span>{{ partei.mieteinnahmen }} €</span>
            </div>
          </v-card-text>
        </v-card>
        <v-card subtitle="Gesamt">
          <v-card-text>
            <div class="d-flex justify-space-between">
              <span>monatl. Rate</span>
              <span>{{ partei.gesamtrate }} €</span>
            </div>
            <div class="d-flex justify-space-between" v-if="partei.kredit.hoehe > 0">
              <span>Laufzeit</span>
              <span>{{ partei.kredit.laufzeit }} Jahre</span>
            </div>
          </v-card-text>
        </v-card>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "FinanzierendeParteien",
  data () {
    return {
      color: ["purple", "green", "yellow", "blue"]
    }
  },
  methods: {
    ...mapMutations(["partei_erstellen", "partei_aendern", "partei_loeschen"]),
    fehlenden_kredit_anteil_berechnen(partei) {
      let fehlender_anteil =
        100 - (this.kredit_anteil_summe - partei.kredit_anteil);
      partei.kredit_anteil = fehlender_anteil > 0 ? fehlender_anteil : 0;
    },
    fehlenden_miet_anteil_berechnen(partei) {
      let fehlender_anteil =
        100 - (this.mieteinnahmen_anteil_summe - partei.mieteinnahmen_anteil);
      partei.mieteinnahmen_anteil = fehlender_anteil > 0 ? fehlender_anteil : 0;
    },
    fehlende_wohnflaeche_berechnen(partei) {
      let fehlender_anteil =
        this.objekt.wohnflaeche -
        (this.wohnflaeche_verteilt_summe - partei.wohnflaeche);
      partei.wohnflaeche = fehlender_anteil > 0 ? fehlender_anteil : 0;
    },
  },
  computed: {
    ...mapGetters(["parteien", "edit_mode", "objekt"]),
    kredit_anteil_summe() {
      if (this.parteien.length == 0) {
        return null;
      }
      return this.parteien
        .map((item) => item.kredit_anteil)
        .reduce((prev, next) => prev + next);
    },
    mieteinnahmen_anteil_summe() {
      if (this.parteien.length == 0) {
        return null;
      }
      return this.parteien
        .map((item) => item.mieteinnahmen_anteil)
        .reduce((prev, next) => prev + next);
    },
    wohnflaeche_verteilt_summe() {
      if (this.parteien.length == 0) {
        return null;
      }
      return (
        this.parteien
          .map((item) => item.wohnflaeche)
          .reduce((prev, next) => prev + next) +
        this.objekt.wohnflaeche_vermietet
      );
    },
  },
  mounted() {
    if (this.parteien.length == 0) {
      this.partei_erstellen();
    }
  },
};
</script>

<script setup></script>
