import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    parteien: [],
    edit_mode: true,
    objekt: null,
  },
  getters: {
    parteien(state) {
      let parteien = state.parteien;
      for (let i = 0; i < parteien.length; i++) {
        let partei = parteien[i];
        let kredit = {};
        kredit.hoehe =
          Math.round(
            (state.objekt.gesamtpreis * parteien[i].kredit_anteil) / 100
          ) - parteien[i].eigenanteil;
        kredit.hoehe = kredit.hoehe > 0 ? kredit.hoehe : 0;
        kredit.ersteTilgung =
          (kredit.hoehe * parteien[i].anfangstilgung) / 100 / 12;
        kredit.rate =
          kredit.ersteTilgung +
          (kredit.hoehe * partei.zinssatz_bank) / 100 / 12;
          kredit.laufzeit = Math.round((Math.log(1 + (partei.zinssatz_bank/100)/(partei.anfangstilgung/100))) / (Math.log(1 + (partei.zinssatz_bank/100))))
        parteien[i].kredit = kredit;
        partei.ruecklagen =
          (state.objekt.ruecklagen * partei.kredit_anteil) / 100;
        parteien[i].nebenkosten =
          (state.objekt.nebenkosten * parteien[i].wohnflaeche) /
          state.objekt.wohnflaeche;
        parteien[i].mieteinnahmen =
          (state.objekt.mieteinnahmen * parteien[i].mieteinnahmen_anteil) / 100;
        partei.gesamtrate = Math.round(
          kredit.rate +
            partei.nebenkosten +
            partei.ruecklagen -
            partei.mieteinnahmen
        );
      }
      return parteien;
    },
    edit_mode(state) {
      return state.edit_mode;
    },
    objekt(state) {
      if (!state.objekt) {
        return null;
      }
      let objekt = state.objekt;
      objekt.gesamtpreis =
        (objekt.kaufpreis *
          (100 +
            parseInt(objekt.makler) +
            objekt.notar +
            objekt.grunderwerbssteuer +
            objekt.grundbuch)) /
          100 +
        objekt.renovierungskosten;
      return objekt;
    },
  },
  mutations: {
    objekt_erstellen(state) {
      state.objekt = {
        name: "Objekt",
        kaufpreis: 735000,
        makler: 3.5,
        renovierungskosten: 0,
        notar: 1.5,
        grunderwerbssteuer: 6,
        grundbuch: 0.5,
        gesamtpreis: 0,
        mieteinnahmen: 0,
        nebenkosten: 1000,
        ruecklagen: 0,
        wohnflaeche: 200,
        wohnflaeche_vermietet: 0,
      };
    },
    objekt_aendern(state, objekt) {
      state.objekt= objekt;
    },
    partei_erstellen(state) {
      let partei = {
        name: "Partei " + (state.parteien.length + 1),
        kredit_anteil: 100 / (state.parteien.length + 1),
        eigenanteil: 0,
        zinssatz_bank: 3.3,
        anfangstilgung: 2.5,
        mieteinnahmen_anteil: 100,
        wohnflaeche: 0,
      };
      state.parteien.push(partei);
    },
    partei_aendern(state, partei) {
      state.parteien[state.parteien.indexOf(partei)] = partei;
    },
    partei_loeschen(state, index) {
      state.parteien.splice(index, 1);
    },
    toggle_edit_mode(state) {
      state.edit_mode = !state.edit_mode;
    },
  },
  actions: {

  },
  modules: {},
  plugins: [createPersistedState()],
});
